import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Row, Col } from "antd";
import corePic from "../../../app/assets/images/core.png";
import manPowerPic from "../../../app/assets/images/manpower-img.png";
import badPic from "../../../app/assets/images/bad-record-img.png";
import suspendPic from "../../../app/assets/images/suspendPic.png";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useEmployee } from "../../../app/hooks/useEmployee";

const EmployeeStatus = ({ onSubmit }) => {
  EmployeeStatus.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { modalStore, updateEmployeeStore } = useStore();
  const {updateStatusModal, setUpdateStatusModalVisible, setSuspendFormModalVisible } = modalStore;
  const { personalInfoFormData } = updateEmployeeStore;
  const { update } = useEmployee();

  const { mutate } = useEmployee({
    pageNumber: 0,
    pageSize: 9999,
  });

  const handleStatusUpdate = (status) => {
    setSelectedStatus(status);
  };

  const handleSuspendStatusUpdate = (status) => {
    setUpdateStatusModalVisible(false)
    setSuspendFormModalVisible(status);
  }

  const handleSave = async (e) => {
    // You can submit the selectedStatus to your server or perform any other action here.
    e.preventDefault();
    try {
      const updatedServiceRecords = personalInfoFormData.serviceRecords.map(
        (record) => {
          return {
            ...record,
            employmentStatus: selectedStatus,
          };
        }
      );

      const updatedFormData = {
        ...personalInfoFormData,
        serviceRecords: updatedServiceRecords,
      };

      if (selectedStatus != null) {
        await update(updatedFormData)
        .then(() => {
          setUpdateStatusModalVisible(false);
          onSubmit();
          mutate();
          toast.success("Successfully Update Employee Status", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } else if (selectedStatus == null && updateStatusModal == true) {
        toast.error("Please Select Status", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSave}>
        <Row gutter={32} justify={"center"} style={{ padding: 20 }}>
          <Col span={6}>
            <button
              className="update-core-btn"
              onClick={() => handleStatusUpdate("Regular")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={corePic} alt="Your Logo" style={{ width: "130px" }} />
                <span style={{ color: "#FFFFFF", fontSize: 13 }}>CORE</span>
              </div>
            </button>
          </Col>
          <Col span={6}>
            <button
              className="update-manPower-btn"
              onClick={() => handleStatusUpdate("ProjectBased")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={manPowerPic}
                  alt="Your Logo"
                  style={{ width: "130px" }}
                />
                <span style={{ color: "#FFFFFF", fontSize: 13 }}>
                  PROJECT BASED/CONSULTATION
                </span>
              </div>
            </button>
          </Col>
          <Col span={6}>
            <button
              className="update-badRecord-btn"
              onClick={() => handleStatusUpdate("Separated")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={badPic} alt="Your Logo" style={{ width: "100px" }} />
                <span style={{ color: "#FFFFFF", fontSize: 13, marginTop: 20 }}>
                  BAD RECORD
                </span>
              </div>
            </button>
          </Col>
          <Col span={6}>
            <button
              className="update-suspend-btn"
              onClick={() => handleSuspendStatusUpdate(true)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={suspendPic} alt="Your Logo" style={{ width: "100px" }} />
                <span style={{ color: "#FFFFFF", fontSize: 13, marginTop: 20 }}>
                  SUSPEND
                </span>
              </div>
            </button>
          </Col>
          <Row gutter={20} justify={"space-between"} style={{ marginTop: 50 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#008C27",
                  borderRadius: "7px",
                  width: "123.87px",
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#FF0000",
                  borderRadius: "7px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={() => setUpdateStatusModalVisible(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Row>
      </form>
    </>
  );
};

export default observer(EmployeeStatus);
