import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Row, Col, DatePicker, Input, Select } from "antd";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { useEmployeeStatus } from "../../../app/hooks/enums/useEmployeeStatus";

const { TextArea } = Input;
//const { Option } = Select;

const SuspendEmployee = ({ onSubmit }) => {
  SuspendEmployee.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  const [effectivityDate, setEffectivityDate] = useState(null);
  const [dateIneffectivity, setDateIneffectivity] = useState(null);
  const [adminRemarks, setAdminRemarks] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState(null);
  const { modalStore, updateEmployeeStore } = useStore();
  const { setSuspendFormModalVisible } = modalStore;
  const { employeeId } = updateEmployeeStore;
  const { updateEmployeeStatus } = useEmployee();
  const { data: statusOptions } = useEmployeeStatus(); // Default to an empty array

  const { mutate } = useEmployee({
    pageNumber: 0,
    pageSize: 9999,
  });

  const handleSave = async (e) => {
    e.preventDefault();

    if (!effectivityDate) {
      toast.error("Please Select Effectivity Date", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!employeeStatus) {
      toast.error("Please Select Employee Status", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }


    try {
      const updatedFormData = {
        employeeId: employeeId,
        employeeStatus: employeeStatus,
        effectivityDate: effectivityDate,
        dateIneffectivity: dateIneffectivity,
        adminRemarks: adminRemarks,
      };

      await updateEmployeeStatus(updatedFormData)
        .then(() => {
          setEffectivityDate(null);
          setAdminRemarks("");
          setEmployeeStatus(null);
          setSuspendFormModalVisible(false);
          onSubmit();
          mutate();
          toast.success("Successfully Updated Employee Status", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSave}>
        <Row gutter={32} justify={"center"} style={{ padding: 20 }}>
          <Col span={12}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              placeholder="Select Effectivity Date"
              onChange={(dateStrings) => {
                const [start, end] = dateStrings;
                setEffectivityDate(start);
                setDateIneffectivity(end);    

              }}
              allowClear={true}
            />
          </Col>
          <Col span={12}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Employee Status"
              onChange={(value) => setEmployeeStatus(value)}
              value={employeeStatus}
            >
              {statusOptions &&
                statusOptions.map((status) => (
                  <Select.Option key={status.value} value={status.description}>
                    {status.description}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col span={24} style={{ marginTop: 20 }}>
            <TextArea
              placeholder="Enter Admin Remarks"
              rows={4}
              value={adminRemarks}
              onChange={(e) => setAdminRemarks(e.target.value)}
            />
          </Col>
        </Row>

        <Row gutter={20} justify={"center"} style={{ marginTop: 50 }}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#008C27",
                borderRadius: "7px",
                width: "123.87px",
              }}
            >
              Save
            </Button>
          </Col>
          <Col>
            <Button
              type="default"
              style={{
                backgroundColor: "#FF0000",
                borderRadius: "7px",
                width: "123.87px",
                color: "#ffff",
              }}
              onClick={() => setSuspendFormModalVisible(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default observer(SuspendEmployee);
